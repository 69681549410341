@import "reset";

@import "colors";
@import "mixins";
@import "fonts";

@import "base";
@import "layout";
@import "vendor";

@import "table";

html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  font-family: $base-font-family;
  line-height: $base-line-height;
  background: $base;
  color: $on-base;
  background-color: #ffff;
  -webkit-font-smoothing: antialiased;
}

p {	
  font-size: 1rem; /* 16px */
}

.navbar {
  width: 100%
}

button:focus {
  outline: none !important;
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0;
    overflow: hidden;
    background-color: rgba(29, 29, 29, 0.932);
  }
}

input[type=text], input[type=email]  {
  border: 1px #e1e1e1 solid;
  border-radius: 0.5rem;
  font-size: 1em;
}

.mini-form {
  border: 1px #e1e1e1 solid;
  border-radius: 0.5rem;
  font-size: 0.9em;
}

mdb-date-picker {
  z-index: 0;
}

ul.stepper {
  margin: 0em !important;
  padding: 0em !important;
  overflow: hidden;
}

.stepper-head {
  padding: 0px !important;
}

.label {
  color: #888888;
  font-size: 12px;
}

.toast-warning-custom {
  background-color: #f0ad4e;
}


// New cover photos marquee


/* this class is used when there are 2 photos in the carousel */
.marquee-2 {
  position: relative;
  animation: marquee-2 25s linear infinite;
}
  @keyframes marquee-2 {
    0% { transform: translateX(0%);} 100% { transform: translateX(-200%); }
  }



/* this class is used when there are 3 photos in the carousel */
.marquee-3 {
  position: relative;
  animation: marquee-3 30s linear infinite;
}
  @keyframes marquee-3 {
    0% { transform: translateX(0%);} 100% { transform: translateX(-300%); }
  }


/* this class is used when there are 4 photos in the carousel */
.marquee-4 {
  position: relative;
  animation: marquee-4 35s linear infinite;
}

  @keyframes marquee-4 {
    0% { transform: translateX(0%);} 100% { transform: translateX(-400%); }
  }
  

// Standardized style for merchant profile picture
.merchant-profile-picture {
  width: 8.5rem;
  height: 8.5rem;
  flex-shrink: 0;
  border-radius: 22.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

// Standardized style for merchant cover photo
.merchant-cover-photo {
  min-height: 300px;
  max-height: 630px;
  overflow: hidden;
  object-position: top;
  background-color: #ffffff;
  border: 1px #f6f6f6 solid;
}
.merchant-cover-photo img {
  width: 100%; 
}

  @media screen and (max-width: 768px) {
    .merchant-cover-photo  {      
      min-height: 150px;
      max-height: 300px;
    }
  }

.contact-cover-photo {
  width: 100%;
  min-height: 300px;
  max-height: 630px;
  object-fit: cover;
  object-position: top;
  border-radius: 0.5rem;
  background-color: #f5ebc4;
}
.contact-cover-photo img {
  width: 100%; 
}

  @media screen and (max-width: 768px) {
    .contact-cover-photo {
      min-height: 150px;
      max-height: 300px;
    }
  }

// Standardized top margin for every page
.page-top-margin {
  margin-top: 5rem;
}

@media screen and (max-width: 768px) {
  .page-top-margin {
    margin-top: 5rem;
  }
}


.product-img {
  width: 100%;
  height: 260px;
  overflow: hidden;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  object-fit: cover;
  border: 1px #f6f6f6 solid;
}

  @media screen and (max-width: 768px) {
    .product-img {
      height: 160px;
    }
  }


.service-img {
  width: 100%;
  height: 180px;
  overflow: hidden;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  object-fit: cover;
  border: 1px #f6f6f6 solid;
}

  @media screen and (max-width: 768px) {
    .service-img {
      height: 120px;
    }
  }

.product-service-detail-img {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  object-fit: cover;
  border: 1px #f6f6f6 solid;
}

  @media screen and (max-width: 768px) {
    .product-service-detail-img {
      height: 300px;
    }
  }

.product-cart-item {
  border-radius: 0.5rem;
  border: 1px #f3f3f3 solid;
}

.post-img {
  width: 100%;
  height: 120px;
  overflow: hidden;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  object-fit: cover;
  border: 1px #f0f0f0 solid;
}

@media screen and (max-width: 768px) {
  .post-img {
    height: 60px;
  }
}

.ui-icon {
  width: 20px;
  height: 20px;
  margin: 3px 8px 0px 6px;
}

.button-icon {
  width: 20px;
  height: 20px;
}

.cursor-pointer {
  cursor: pointer;
}


.border-lite {
  border-radius: 1rem;
  border: 1px #f0f0f0 solid;
}


.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ededed;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ededed;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ededed;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #7fc074;
  color: #fff;
}

.stepper-item.active .step-counter {
  background-color: #fff;
  border: 2px solid #7fc074;
  color: #7fc074;
  font-weight: bold;
  font-size: 22px;
  width: 60px;
  height: 60px;
  top: -10px;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #7fc074;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.circle {
  border-radius: 100px;
}

