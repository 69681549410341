@import "variables";

.table {
  th,
  td {
    vertical-align: middle;
    border-top: none;
    border-bottom: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-top: none;
    border-bottom: $table-border-width solid $table-border-color;
  }

  tbody + tbody {
    border-top: $table-border-width solid $table-border-color;
  }

  > :not(:first-child) {
    border-top: none;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom: $table-border-width solid $table-border-color;
  }
}

.datatable {
  tbody {
    tr:last-child {
      height: 0px;
    }
  }
}

.item-col-width {
  width: 35% !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}
