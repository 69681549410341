.mat-snackbar {
    &_error {
        background: $error;
        color: $on-error;
    }

    &_success {
        background: $success;
        color: $on-success;
    }
}