* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: 0;
}

html {
	font-size: 16px;
	line-height: 1.3;
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

a, button {
	background: none;
	border: none;
	text-decoration: none;
	cursor: pointer;
	-webkit-text-decoration-skip: objects;
}

button::-moz-focus-inner {
	border: 0;
}

a:active,
a:hover,
button:active,
button:hover {
	outline-width: 0;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline dotted;
}

img {
	display: block;
	margin: 0;
	border: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

progress {
	vertical-align: baseline;
}

template,
[hidden] {
	display: none;
}

table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	font-size: 100%;
	vertical-align: middle;
	background: transparent;
	border-collapse: collapse;
}

table {
	width: 100%;
}

b,
strong {
	font-weight: bolder;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #ff0;
	color: #000;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

svg:not(:root) {
	overflow: hidden;
}

code,
kbd,
pre,
samp {
	font-family: monospace;
	font-size: 1em;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

input, textarea, select, button {
	font: inherit;
}
