$base: #eeeeee;
$base-dark: #e0e0e0;
$base-light: #ffffff;
$on-base: #444444;

$primary: #00bcd4;
$primary-dark: #008ba3;
$primary-light: #62efff;
$on-primary: #ffffff;

$secondary: #ffd54f;
$secondary-dark: #c8a415;
$secondary-light: #ffff81;
$on-secondary: #000000;

$error: #B00020;
$on-error: #ffffff;

$success: #99aa00;
$on-success: #ffffff;


.bg-color-white { background-color: white; }
.bg-color-gold { background-color: gold; }
.bg-color-success { background-color: $success; }
.bg-color-magenta { background-color: #340745; }
.bg-color-red { background-color: #c16964; }
.bg-color-light-gray { background-color: #fefefe; }
.bg-color-light-gold {  background-color:#efdc91; }

.text-light-gray { color: #e9e9e9; }
.text-gray { color: #777777; }
.text-red { color: #e14a4a; }
.text-cyan { color:#008ba3; }
.text-magenta { color:#340745; }
.text-gold { color: #FBCB0E; }

.text-dark-blue { color: #232352; }

.text-light-blue { color:#d5d5f9; }
.text-light-gold { color: #efdc91; }
.text-light-green { color:#b8f2b1; }
.text-light-magenta { color:#d57bf6; }
.text-light-cyan { color:#6fcfde; }
.text-light-red { color: #f17676; }
.text-light-orange { color: #eda350; }


.billing-badge {
  display: inline-block;
  white-space: nowrap;
  min-width: 5.6em;
  padding: 3px 7px;
  text-align: center;
  font-size: 0.7em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &.success {
    background-color: #E3F8DB;
    color: #369B8F;
  }
  &.danger {
    background: #F9DED5 !important;
    color: #F55;
  }
  &.warning {
    background: #fbefda !important;
    color: #F55;
  }
  &.info {
    background-color: #F8F0DB;
    color: #9B7C36;
  }
  &.light {
    background-color: #EEEBEB;
    color: #888888;
  }
}
