@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$base-font-family: "Inter", sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.2;

p { margin: 0.02px; }




//****** From this point: Font weights that will be replaced by TailwindCSS in the future**********
.fw-light { font-weight: 300; }
.fw-normal { font-weight: 400; }
.fw-semibold { font-weight: 600; }
.fw-bold { font-weight: 700; }
.fs-6 {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
.fs-5 {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.fs-4 {	
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
.fs-3	{
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.fs-2	{
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.fs-1	{
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
//******** delete  up to this point whenm done fully transitioning to TailwindCSS